/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP506M
 * 화면설명: FC활동 / 업적현황
 * 접근권한: 지점장
 * 작 성 일: 2022.06.24
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container :show-title="true" title="FC활동/업적현황" type="subpage" action-type="search" @action-search-click="fn_Open514Pop">
  <template #frame-header-fixed>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-sum">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총 {{ Number(mockData.length) - 1 === -1 ? 0 : Number(mockData.length) - 1}}명</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
  </template>
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-contract-list no-data" v-if="mockData.length === 0">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span v-if="nodataCd === 1">상세조회 조건을 설정해 주세요.</span>
                <span v-else>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-contract-list fc-active">
          <mo-list :list-data="mockData">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span :class="{'ns-blue' : item.mobslUserNm !== '총계'}" @click="fn_Open511Pop(item.mobslUserNm, item.userEno)">{{item.mobslUserNm}}</span><span class="fc-txt">{{item.userEno}}</span><span class="fc-txt">{{item.salesPesnTeamId}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span>신계약</span>
                      <span class="txtBlue">{{item.ncontCnt}} 건</span>
                    </div>
                    <div class="txt_right" v-if="item.mobslUserNm !== '총계'" @click.stop="fn_Open507Pop(item)">
                      <mo-badge class="badge-sample-badge blue" text="주간활동현황" shape="status"></mo-badge>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span>동의서</span>
                      <span>{{item.wcnstCnt}}명</span>
                    </div>
                    <div class="txt_right">
                      <span>보장설계</span>
                      <span>{{item.covanCnt}}명</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span>가입설계</span>
                      <span>{{item.entplCnt}}명</span>
                    </div>
                    <div class="txt_right">
                      <span>청약서</span>
                      <span>{{item.ofrfmCnt}}명</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPAP507P from '@/ui/ap/MSPAP507P'
  import MSPAP514P from '@/ui/ap/MSPAP514P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP506M",
    screenId: "MSPAP506M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    console.log('this.lv_UserInfo', this.lv_UserInfo)
    this.fn_SearchUserSrvcMngScCd()
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPAP506M')

    },
    beforeDestroy() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      DEBUG: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' ),
      lv_Set: new Map(),
      from: new Date(),
      to: new Date(),
      mokupdata: [],
      lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
      targetMonth: new Date().toISOString(),
      // lv_HofOrg: {key: '00000000', label: '선택하세요'}, // 선택사업부
      // lv_DofOrg: {key: '0', label: '선택하세요'}, // 선택지역단
      // lv_FofOrg: {key: '0', label: '선택하세요'}, // 선택지점
      lv_FofCnslt: {key: '0', label: '전체'}, // 전체 컨설턴트
      lv_HofOrgData: [], // 사업부 목록
      lv_DofOrgData: [], // 지역단 목록
      lv_FofOrgData: [], // 지점 목록
      lv_FofCnsltData: [], // 컨설턴트 목록
      lv_OrgAuth: [], // 지점권한
      lv_IsFirst: true, // 최초조회 여부
      lv_UserInfo: '',
      isManager: false, // 관리자여부
      mockData: [],
      nodataCd: 1, // 1: 데이터가 없습니다. 2: 검색결과가 없습니다
      searchKeyword: '',
      productName: '',
      insuredName: '',
      lv_SearchData: {},
      lv_popup514: {}, // MSPAP514P 상세조회 팝업
      lv_popup507: {}, // MSPAP507P 주간활동현황 팝업
      lv_popup511: {},
      srchPopupShowYn: false,
      lv_AlertPopup: {}
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /******************************************************************************
    * Function명 : fn_SearchMntlyActCntList
    * 설명       : 월간활동건수조회
    *
   ******************************************************************************/
    fn_SearchMntlyActCntList: async function (pData) {
      console.log('fn_SearchMntlyActCntList', pData)
      let lv_Vm = this
      if (pData === 'init') {
        console.log('init Start')
        let pParams =
                    { smupStndYm: lv_Vm.targetMonth.substring(0, 7).replace('-', ''),
                      mobslFofOrgNo: lv_Vm.lv_UserInfo.onpstFofOrgNo,
                      mobslHofOrgNo: lv_Vm.lv_UserInfo.onpstHofOrgNo,
                      // userEno: lv_Vm.lv_FofCnslt.key
                      userEno: '0'
                    }

        let trnstId = 'txTSSAP04S7'

        lv_Vm.mockData.splice(0, lv_Vm.mockData.length)
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body != null && response.body.aPCmpltSchdlBmngrCntVO != null && response.body.aPCmpltSchdlBmngrCntVO.length > 0) {
              lv_Vm.mockData = response.body.aPCmpltSchdlBmngrCntVO
              let goalCntWgtVO = response.body.aPActGoalCntWgtVO
              // 활동가중치
              let mobslWcnstWgt = Number(goalCntWgtVO.mobslWcnstWgtRat)
              let mobslCovanWgt = Number(goalCntWgtVO.mobslCovanWgtRat)
              let mobslEntplWgt = Number(goalCntWgtVO.mobslEntplWgtRat)
              let mobslOfrfmWgt = Number(goalCntWgtVO.mobslOfrfmWgtRat)
              let mobslNcontWgt = Number(goalCntWgtVO.mobslNcontWgtRat)
              let mobslTelWgt = Number(goalCntWgtVO.mobslTelWgtRat)
              let mobslCharWgt = Number(goalCntWgtVO.mobslCharWgtRat)

              // 활동목표
              let goalMobslWcnstCnt = Number(goalCntWgtVO.mobslWcnstCnt)
              let goalMobslCovanCnt = Number(goalCntWgtVO.mobslCovanCnt)
              let goalMobslEntplCnt = Number(goalCntWgtVO.mobslEntplCnt)
              let goalMobslOfrfmCnt = Number(goalCntWgtVO.mobslOfrfmCnt)
              let goalMobslNcontCnt = Number(goalCntWgtVO.mobslNcontCnt)
              let goalMobslTelCnt = Number(goalCntWgtVO.mobslTelCnt)
              let goalMobslCharCnt = Number(goalCntWgtVO.mobslCharCnt)

              // goalMobslWcnstCnt = 20
              // goalMobslCovanCnt = 15
              // goalMobslEntplCnt = 15
              // goalMobslOfrfmCnt = 9
              // goalMobslNcontCnt = 3
              // goalMobslTelCnt = 100
              // goalMobslCharCnt = 100
              // mobslWcnstWgt = 20
              // mobslCovanWgt = 20
              // mobslEntplWgt = 15
              // mobslOfrfmWgt = 15
              // mobslNcontWgt = 10
              // mobslTelWgt = 10
              // mobslCharWgt = 10

              // 활동점수
              let scoreMobslWcnst = 0
              let scoreMobslCovan = 0
              let scoreMobslEntpl = 0
              let scoreMobslOfrfm = 0
              let scoreMobslNcont = 0
              let scoreMobslTel = 0
              let scoreMobslChar = 0

              let temNmList = []
              let temNmInfo = {}

              let temNm = ''
              lv_Vm.mockData.forEach((item, index) => {
                if (index === 0) {
                  item.salesPesnTeamId = null // 총계부분
                } else {
                  if (item.salesPesnTeamId === '-') {
                    item.salesPesnTeamId = item.salesPesnTeamId
                  } else {
                    item.salesPesnTeamId = Number(item.salesPesnTeamId) + '팀'
                  }

                  if (item.mobslUserNm !== '총계') {
                    scoreMobslWcnst = 0
                    scoreMobslCovan = 0
                    scoreMobslEntpl = 0
                    scoreMobslOfrfm = 0
                    scoreMobslNcont = 0
                    scoreMobslTel = 0
                    scoreMobslChar = 0

                    // 활동점수
                    // item.wcnstCnt = 80
                    // item.covanCnt = 80
                    // item.entplCnt = 80
                    // item.ofrfmCnt = 80
                    // item.ncontCnt = 80
                    // item.telCnt = 80
                    // item.charCnt = 0
                    if (item.wcnstCnt !== 0 && goalMobslWcnstCnt !== 0 && mobslWcnstWgt !== 0) {
                      if (item.wcnstCnt > goalMobslWcnstCnt) {
                        scoreMobslWcnst = mobslWcnstWgt
                      } else {
                        scoreMobslWcnst = ((item.wcnstCnt / goalMobslWcnstCnt) * 100) * (mobslWcnstWgt / 100)
                        scoreMobslWcnst = Math.floor(scoreMobslWcnst * 10) / 10
                      }
                    }
                    if (item.covanCnt !== 0 && goalMobslCovanCnt !== 0 && mobslCovanWgt !== 0) {
                      if (item.covanCnt > goalMobslCovanCnt) {
                        scoreMobslCovan = mobslCovanWgt
                      } else {
                        scoreMobslCovan = ((item.covanCnt / goalMobslCovanCnt) * 100) * (mobslCovanWgt / 100)
                        scoreMobslCovan = Math.floor(scoreMobslCovan * 10) / 10
                      }
                    }
                    if (item.entplCnt !== 0 && goalMobslEntplCnt !== 0 && mobslEntplWgt !== 0) {
                      if (item.entplCnt > goalMobslEntplCnt) {
                        scoreMobslEntpl = mobslEntplWgt
                      } else {
                        scoreMobslEntpl = ((item.entplCnt / goalMobslEntplCnt) * 100) * (mobslEntplWgt / 100)
                        scoreMobslEntpl = Math.floor(scoreMobslEntpl * 10) / 10
                      }
                    }
                    if (item.ofrfmCnt !== 0 && goalMobslOfrfmCnt !== 0 && mobslOfrfmWgt !== 0) {
                      if (item.ofrfmCnt > goalMobslOfrfmCnt) {
                        scoreMobslOfrfm = mobslOfrfmWgt
                      } else {
                        scoreMobslOfrfm = ((item.ofrfmCnt / goalMobslOfrfmCnt) * 100) * (mobslOfrfmWgt / 100)
                        scoreMobslOfrfm = Math.floor(scoreMobslOfrfm * 10) / 10
                      }
                    }
                    if (item.ncontCnt !== 0 && goalMobslNcontCnt !== 0 && mobslNcontWgt !== 0) {
                      if (item.ncontCnt > goalMobslNcontCnt) {
                        scoreMobslNcont = mobslNcontWgt
                      } else {
                        scoreMobslNcont = ((item.ncontCnt / goalMobslNcontCnt) * 100) * (mobslNcontWgt / 100)
                        scoreMobslNcont = Math.floor(scoreMobslNcont * 10) / 10
                      }
                    }
                    if (item.telCnt !== 0 && goalMobslTelCnt !== 0 && mobslTelWgt !== 0) {
                      if (item.telCnt > goalMobslTelCnt) {
                        scoreMobslTel = mobslTelWgt
                      } else {
                        scoreMobslTel = ((item.telCnt / goalMobslTelCnt) * 100) * (mobslTelWgt / 100)
                        scoreMobslTel = Math.floor(scoreMobslTel * 10) / 10
                      }
                    }
                    if (item.charCnt !== 0 && goalMobslCharCnt !== 0 && mobslCharWgt !== 0) {
                      if (item.charCnt > goalMobslCharCnt) {
                        scoreMobslChar = mobslCharWgt
                      } else {
                        scoreMobslChar = ((item.charCnt / goalMobslCharCnt) * 100) * (mobslCharWgt / 100)
                        scoreMobslChar = Math.floor(scoreMobslChar * 10) / 10
                      }
                    }
                    let totScore = scoreMobslWcnst + scoreMobslCovan + scoreMobslEntpl + scoreMobslOfrfm + scoreMobslNcont + scoreMobslTel + scoreMobslChar
                    totScore = Math.floor(totScore * 10) / 10
                    item.score = totScore
                    item.scoreTxt = totScore.toFixed(1)

                    // team list를 만들기 위해서
                    if (temNm !== item.salesPesnTeamId) {
                      temNmInfo.nm = item.salesPesnTeamId
                      temNmInfo.cnt = 0
                      temNmInfo.score = 0
                      temNmList.push(temNmInfo)
                      temNmInfo = {}
                      temNm = item.salesPesnTeamId
                    }
                  }
                }
              })

              // 팀 원, 팀합계
              lv_Vm.mockData.forEach((mockDataItem, inx) => {
                if (mockDataItem.mobslUserNm !== '총계') {
                  temNmList.forEach((temNmListItem, jnx) => {
                    if (mockDataItem.salesPesnTeamId === temNmListItem.nm) {
                      temNmListItem.score += mockDataItem.score
                      temNmListItem.cnt = temNmListItem.cnt + 1
                    }
                  })
                }
              })

              // 팀 평균 점수 넣기위해
              temNmList.forEach((temNmListItem, jnx) => {
                temNmListItem.score = temNmListItem.score / temNmListItem.cnt
                temNmListItem.score = Math.floor(temNmListItem.score * 10) / 10
              })
              let totTeamScore = 0
              temNmList.forEach((temNmListItem, jnx) => {
                totTeamScore += temNmListItem.score
              })

              totTeamScore = totTeamScore / temNmList.length
              totTeamScore = Math.floor(totTeamScore * 10) / 10
              lv_Vm.mockData.forEach((mockDataItem, inx) => {
                if (inx === 0) {
                  mockDataItem.scoreTxt = totTeamScore.toFixed(1)
                  // if (mockDataItem.score.indexOf('.') > 0) {

                  // }
                } else {
                  if (mockDataItem.mobslUserNm === '총계') {
                    temNmList.forEach((temNmListItem, jnx) => {
                      if (mockDataItem.salesPesnTeamId === temNmListItem.nm) {
                        mockDataItem.scoreTxt = temNmListItem.score.toFixed(1)
                      }
                    })
                  }
                }
              })
              for (let index = 0; index < lv_Vm.mockData.length; index++) {
                let mockDataItem = lv_Vm.mockData[index]
                if (mockDataItem.mobslUserNm === '총계' && index !== 0) {
                  lv_Vm.mockData.splice(index, 1)
                }
              }
            } else {
              lv_Vm.nodataCd = 2
            }
            for (let index = 0; index < lv_Vm.mockData.length; index++) {
              let mockDataItem = lv_Vm.mockData[index]
              if (mockDataItem.mobslUserNm === '총계') {
                lv_Vm.mockData[index].userEno = ''
                break
              }
            }
          })

      } else {
          let pParams =
                      { smupStndYm: pData.searchMonth[0] + pData.searchMonth[1],
                        mobslFofOrgNo: pData.fofOrg.key,
                        mobslHofOrgNo: pData.hofOrg.key,
                        userEno: pData.cnslt.key
                      }

          let trnstId = 'txTSSAP04S7'

          lv_Vm.mockData.splice(0, lv_Vm.mockData.length)
          this.post(lv_Vm, pParams, trnstId, 'S')
            .then(function (response) {
              if (response.body != null && response.body.aPCmpltSchdlBmngrCntVO != null && response.body.aPCmpltSchdlBmngrCntVO.length > 0) {
                lv_Vm.mockData = response.body.aPCmpltSchdlBmngrCntVO
                console.log('lv_Vm.aPCmpltSchdlBmngrCntVO=', response.body.aPCmpltSchdlBmngrCntVO)
                let goalCntWgtVO = response.body.aPActGoalCntWgtVO
                // 활동가중치
                let mobslWcnstWgt = Number(goalCntWgtVO.mobslWcnstWgtRat)
                let mobslCovanWgt = Number(goalCntWgtVO.mobslCovanWgtRat)
                let mobslEntplWgt = Number(goalCntWgtVO.mobslEntplWgtRat)
                let mobslOfrfmWgt = Number(goalCntWgtVO.mobslOfrfmWgtRat)
                let mobslNcontWgt = Number(goalCntWgtVO.mobslNcontWgtRat)
                let mobslTelWgt = Number(goalCntWgtVO.mobslTelWgtRat)
                let mobslCharWgt = Number(goalCntWgtVO.mobslCharWgtRat)

                // 활동목표
                let goalMobslWcnstCnt = Number(goalCntWgtVO.mobslWcnstCnt)
                let goalMobslCovanCnt = Number(goalCntWgtVO.mobslCovanCnt)
                let goalMobslEntplCnt = Number(goalCntWgtVO.mobslEntplCnt)
                let goalMobslOfrfmCnt = Number(goalCntWgtVO.mobslOfrfmCnt)
                let goalMobslNcontCnt = Number(goalCntWgtVO.mobslNcontCnt)
                let goalMobslTelCnt = Number(goalCntWgtVO.mobslTelCnt)
                let goalMobslCharCnt = Number(goalCntWgtVO.mobslCharCnt)

                // goalMobslWcnstCnt = 20
                // goalMobslCovanCnt = 15
                // goalMobslEntplCnt = 15
                // goalMobslOfrfmCnt = 9
                // goalMobslNcontCnt = 3
                // goalMobslTelCnt = 100
                // goalMobslCharCnt = 100
                // mobslWcnstWgt = 20
                // mobslCovanWgt = 20
                // mobslEntplWgt = 15
                // mobslOfrfmWgt = 15
                // mobslNcontWgt = 10
                // mobslTelWgt = 10
                // mobslCharWgt = 10

                // 활동점수
                let scoreMobslWcnst = 0
                let scoreMobslCovan = 0
                let scoreMobslEntpl = 0
                let scoreMobslOfrfm = 0
                let scoreMobslNcont = 0
                let scoreMobslTel = 0
                let scoreMobslChar = 0

                let temNmList = []
                let temNmInfo = {}

                let temNm = ''
                lv_Vm.mockData.forEach((item, index) => {
                  if (index === 0) {
                    item.salesPesnTeamId = null // 총계부분
                  } else {
                    if (item.salesPesnTeamId === '-') {
                      item.salesPesnTeamId = item.salesPesnTeamId
                    } else {
                      item.salesPesnTeamId = Number(item.salesPesnTeamId) + '팀'
                    }

                    if (item.mobslUserNm !== '총계') {
                      scoreMobslWcnst = 0
                      scoreMobslCovan = 0
                      scoreMobslEntpl = 0
                      scoreMobslOfrfm = 0
                      scoreMobslNcont = 0
                      scoreMobslTel = 0
                      scoreMobslChar = 0

                      // 활동점수
                      // item.wcnstCnt = 80
                      // item.covanCnt = 80
                      // item.entplCnt = 80
                      // item.ofrfmCnt = 80
                      // item.ncontCnt = 80
                      // item.telCnt = 80
                      // item.charCnt = 0
                      if (item.wcnstCnt !== 0 && goalMobslWcnstCnt !== 0 && mobslWcnstWgt !== 0) {
                        if (item.wcnstCnt > goalMobslWcnstCnt) {
                          scoreMobslWcnst = mobslWcnstWgt
                        } else {
                          scoreMobslWcnst = ((item.wcnstCnt / goalMobslWcnstCnt) * 100) * (mobslWcnstWgt / 100)
                          scoreMobslWcnst = Math.floor(scoreMobslWcnst * 10) / 10
                        }
                      }
                      if (item.covanCnt !== 0 && goalMobslCovanCnt !== 0 && mobslCovanWgt !== 0) {
                        if (item.covanCnt > goalMobslCovanCnt) {
                          scoreMobslCovan = mobslCovanWgt
                        } else {
                          scoreMobslCovan = ((item.covanCnt / goalMobslCovanCnt) * 100) * (mobslCovanWgt / 100)
                          scoreMobslCovan = Math.floor(scoreMobslCovan * 10) / 10
                        }
                      }
                      if (item.entplCnt !== 0 && goalMobslEntplCnt !== 0 && mobslEntplWgt !== 0) {
                        if (item.entplCnt > goalMobslEntplCnt) {
                          scoreMobslEntpl = mobslEntplWgt
                        } else {
                          scoreMobslEntpl = ((item.entplCnt / goalMobslEntplCnt) * 100) * (mobslEntplWgt / 100)
                          scoreMobslEntpl = Math.floor(scoreMobslEntpl * 10) / 10
                        }
                      }
                      if (item.ofrfmCnt !== 0 && goalMobslOfrfmCnt !== 0 && mobslOfrfmWgt !== 0) {
                        if (item.ofrfmCnt > goalMobslOfrfmCnt) {
                          scoreMobslOfrfm = mobslOfrfmWgt
                        } else {
                          scoreMobslOfrfm = ((item.ofrfmCnt / goalMobslOfrfmCnt) * 100) * (mobslOfrfmWgt / 100)
                          scoreMobslOfrfm = Math.floor(scoreMobslOfrfm * 10) / 10
                        }
                      }
                      if (item.ncontCnt !== 0 && goalMobslNcontCnt !== 0 && mobslNcontWgt !== 0) {
                        if (item.ncontCnt > goalMobslNcontCnt) {
                          scoreMobslNcont = mobslNcontWgt
                        } else {
                          scoreMobslNcont = ((item.ncontCnt / goalMobslNcontCnt) * 100) * (mobslNcontWgt / 100)
                          scoreMobslNcont = Math.floor(scoreMobslNcont * 10) / 10
                        }
                      }
                      if (item.telCnt !== 0 && goalMobslTelCnt !== 0 && mobslTelWgt !== 0) {
                        if (item.telCnt > goalMobslTelCnt) {
                          scoreMobslTel = mobslTelWgt
                        } else {
                          scoreMobslTel = ((item.telCnt / goalMobslTelCnt) * 100) * (mobslTelWgt / 100)
                          scoreMobslTel = Math.floor(scoreMobslTel * 10) / 10
                        }
                      }
                      if (item.charCnt !== 0 && goalMobslCharCnt !== 0 && mobslCharWgt !== 0) {
                        if (item.charCnt > goalMobslCharCnt) {
                          scoreMobslChar = mobslCharWgt
                        } else {
                          scoreMobslChar = ((item.charCnt / goalMobslCharCnt) * 100) * (mobslCharWgt / 100)
                          scoreMobslChar = Math.floor(scoreMobslChar * 10) / 10
                        }
                      }
                      let totScore = scoreMobslWcnst + scoreMobslCovan + scoreMobslEntpl + scoreMobslOfrfm + scoreMobslNcont + scoreMobslTel + scoreMobslChar
                      totScore = Math.floor(totScore * 10) / 10
                      item.score = totScore
                      item.scoreTxt = totScore.toFixed(1)

                      // team list를 만들기 위해서
                      if (temNm !== item.salesPesnTeamId) {
                        temNmInfo.nm = item.salesPesnTeamId
                        temNmInfo.cnt = 0
                        temNmInfo.score = 0
                        temNmList.push(temNmInfo)
                        temNmInfo = {}
                        temNm = item.salesPesnTeamId
                      }
                    }
                  }
                })

                // 팀 원, 팀합계
                lv_Vm.mockData.forEach((mockDataItem, inx) => {
                  if (mockDataItem.mobslUserNm !== '총계') {
                    temNmList.forEach((temNmListItem, jnx) => {
                      if (mockDataItem.salesPesnTeamId === temNmListItem.nm) {
                        temNmListItem.score += mockDataItem.score
                        temNmListItem.cnt = temNmListItem.cnt + 1
                      }
                    })
                  }
                })

                // 팀 평균 점수 넣기위해
                temNmList.forEach((temNmListItem, jnx) => {
                  temNmListItem.score = temNmListItem.score / temNmListItem.cnt
                  temNmListItem.score = Math.floor(temNmListItem.score * 10) / 10
                })
                let totTeamScore = 0
                temNmList.forEach((temNmListItem, jnx) => {
                  totTeamScore += temNmListItem.score
                })

                totTeamScore = totTeamScore / temNmList.length
                totTeamScore = Math.floor(totTeamScore * 10) / 10
                lv_Vm.mockData.forEach((mockDataItem, inx) => {
                  if (inx === 0) {
                    mockDataItem.scoreTxt = totTeamScore.toFixed(1)
                    // if (mockDataItem.score.indexOf('.') > 0) {

                    // }
                  } else {
                    if (mockDataItem.mobslUserNm === '총계') {
                      temNmList.forEach((temNmListItem, jnx) => {
                        if (mockDataItem.salesPesnTeamId === temNmListItem.nm) {
                          mockDataItem.scoreTxt = temNmListItem.score.toFixed(1)
                        }
                      })
                    }
                  }
                })
                for (let index = 0; index < lv_Vm.mockData.length; index++) {
                  let mockDataItem = lv_Vm.mockData[index]
                  if (mockDataItem.mobslUserNm === '총계' && index !== 0) {
                    lv_Vm.mockData.splice(index, 1)
                  }
                }
              } else {
                lv_Vm.nodataCd = 2
              }
              for (let index = 0; index < lv_Vm.mockData.length; index++) {
                let mockDataItem = lv_Vm.mockData[index]
                if (mockDataItem.mobslUserNm === '총계') {
                  lv_Vm.mockData[index].userEno = ''
                  break
                }
              }
            })
      }
    },
    /******************************************************************************
     * Function명 : fn_Open514Pop
     * 설명       : 상세조회조건 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
      fn_Open514Pop () {
        console.log('this.lv_SearchData', Object.keys(this.lv_SearchData).length)
        this.srchPopupShowYn = true // 상세조회조건 활성화시 true
        if (Object.keys(this.lv_SearchData).length === 0) {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
          properties: {
            title: "상세조회조건",
            pSrnId: 'MSPAP506M',
            userInfo: this.getStore('userInfo').getters.getUserInfo,
            pSearchData: this.lv_SearchData
          },
          listeners: {
            onPopupConfirm: (pData) => {
              console.log("onPopUpClose");
              this.$bottomModal.close(this.lv_popup514);
              console.log('substring', pData.cnslt.label.substring(5, 15))
              Object.assign(this.lv_SearchData, pData)
              this.fn_SearchMntlyActCntList(pData)
              this.lv_IsFirst = false

              console.log('this.lv_SearchData', this.lv_SearchData)
              
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup514);
            }
          }
        }); 
        } else {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
          properties: {
            title: "상세조회조건",
            pSrnId: 'MSPAP506M',
            userInfo: this.getStore('userInfo').getters.getUserInfo,
            pSearchData: this.lv_SearchData

          },
          listeners: {
            onPopupConfirm: (pData) => {
              console.log("onPopUpClose");
              this.$bottomModal.close(this.lv_popup514);

              Object.assign(this.lv_SearchData, pData)
              this.fn_SearchMntlyActCntList(pData)
              console.log('this.lv_SearchData', this.lv_SearchData)
              this.lv_IsFirst = false
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup514);
            }
          }
        });
        }
      },
    /******************************************************************************
     * Function명 : fn_Open507Pop
     * 설명       : 주간활동현황 팝업 호출
     * 
     ******************************************************************************/
      fn_Open507Pop (pData) {
        console.log('OpenPopup507 ->pData :', pData)
        console.log('OpenPopup507 ->userEno :', pData.userEno)
        console.log('OpenPopup507 ->searchMonth :', this.lv_SearchData.searchMonth)
        if (this.lv_SearchData.searchMonth === undefined) {
            this.lv_popup507 = this.$bottomModal.open(MSPAP507P, {
              properties: {
                title: "주간활동현황",
                pUserEno: pData.userEno,
                pActYm: moment(new Date()).format('YYYYMM')
              },
              listeners: {
                // onPopupConfirm: (pData) => {
                //   console.log("onPopUpClose");
                //   this.$bottomModal.close(this.lv_popup507);

                //   Object.assign(this.lv_SearchData, pData)
                //   this.fn_SearchMntlyActCntList(pData)
                //   console.log('this.lv_SearchData', this.lv_SearchData)
                  
                // },
                onPopupClose: () => {
                  this.$bottomModal.close(this.lv_popup507);
                }
              }
            })
        } else {
          this.lv_popup507 = this.$bottomModal.open(MSPAP507P, {
            properties: {
              title: "주간활동현황",
              pUserEno: pData.userEno,
              pActYm: this.lv_SearchData.searchMonth[0] + this.lv_SearchData.searchMonth[1]
            },
            listeners: {
              // onPopupConfirm: (pData) => {
              //   console.log("onPopUpClose");
              //   this.$bottomModal.close(this.lv_popup507);

              //   Object.assign(this.lv_SearchData, pData)
              //   this.fn_SearchMntlyActCntList(pData)
              //   console.log('this.lv_SearchData', this.lv_SearchData)
                
              // },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_popup507);
              }
            }
          })
        }
      },
    /******************************************************************************
     * Function명 : fn_Open511Pop
     * 설명       : FC문자/전화 팝업 호출
     * 
     ******************************************************************************/
      fn_Open511Pop (cnsltNm, cnsltNo) {
        this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: cnsltNm,
            pCnsltNo: cnsltNo,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log("onPopupConfirm");
              this.$bottomModal.close(this.lv_popup511);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup511);
            }
          }
        });
      },
      /******************************************************************************
      * Function명 : fn_Init
      * 설명       : 화면 초기화
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_Init () {
        this.mokupdata.slice(0)
        this.mokupdata = []
      },
      /******************************************************************************
      * Function명 : fn_SearchUserSrvcMngScCd
      * 설명       : 로그인 사용자의 영업채널구분코드 검색
      *              사용자의 영업채널구분코드가 FC(FC, SFP지점장), FS(금융서비스), GP(GFC지점장), SA(WM지점장) 이면 최초화면진입 시 데이터 조회
      *              AM(AFC), GA(GA)이면 대리점 선택을 위한 상세조회팝업 이동 필요
      *              2022-08-10 차민아 프로 요구사항
      *              기존 사랑On권한 기반(F110, A110, G110 ...)으로 설계가 되어 있었으나, 권한 이름만 존재하고 실제 운영계의 역할과 연계가 되어있지 않음을 확인(ex) AFC지점장의 역할권한이 F110, A110 등록)
      *              지점장의 역할이 중복으로 들어갈 수 있으므로 영업채널구분코드를 통하여 소속 채널을 구분하도록 수정.
      * 인자       :
      * Return     :
      ******************************************************************************/
   fn_SearchUserSrvcMngScCd () {
     let t_srvcMngScCd = this.lv_UserInfo.srvcMngScCd
     let t_findIdxUserSrvcMngScCd = 'FC,FS,GP,SA'.indexOf(t_srvcMngScCd)
     let t_indexOfAMGA = 'AM,GA'.indexOf(t_srvcMngScCd)
     console.log('t_indexOfAMGA', t_indexOfAMGA)
     if (t_findIdxUserSrvcMngScCd !== -1) {
        this.fn_SearchMntlyActCntList('init')
        this.lv_IsFirst = false
     } else {
       this.fn_Init()
       this.fn_RoleBottomAlert()
     }
   },
      /******************************************************************************
      * Function명 : fn_BackBtnClicked
      * 설명       : 백 버튼 클릭이벤트(Device Back 포함)
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_BackBtnClicked () {
        // 상세조회조건 팝업을 호출한 적이 있으면 뒤로가기 대신 화면을 초기화 한다.
        if (this.srchPopupShowYn) {
          this.lv_SearchData = {}
          this.fn_SearchUserSrvcMngScCd()
          this.srchPopupShowYn = false
          this.lv_IsFirst = true
          this.nodataCd = 1
        } else {
          this.$router.go(-1)
        }
      },
    /******************************************************************************
    * Function명 : fn_RoleBottomAlert
    * 설명       : 단순알림 팝업 호출
    ******************************************************************************/
      fn_RoleBottomAlert () {
        this.lv_AlertPopup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: '지점(대리점)을 먼저 선택해 주세요.'
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_Open514Pop()
              this.$bottomModal.close(this.lv_AlertPopup);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPopup);
            }
          }
        },
        {properties: {preventTouchClose : false}}
        )},
    } // method end
  };
</script>
<style scoped>
</style>